import { useQuery} from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { Queries } from "./common_queries";

export const Insights = (category,limit) => {
    return useQuery(Queries.Insights,{
        variables: {category: category, limit}
    });
}

export const InsightsTabs = (category) => {
    return useQuery(Queries.InsightsTabs);
}

export const InsightsDetails = (url) => {
    return useQuery(Queries.InsightsDetails,{
        variables: {url: url}
    });
}

// export const OtherInsights = (id, filter_tag) => {
//     return useQuery(Queries.OtherInsights,{
//         variables: {id: id, filter_tag:filter_tag}
//     });
// }

export const OtherInsights = (filter_tag) => {
    return useQuery(Queries.OtherInsights,{
        variables: {filter_tag:filter_tag}
    });
}

export const NewProjectSale = (city_name, type) => {
    return useQuery(Queries.NewProjectSale,{
        variables: {city_name:city_name, type: type}
    });
}

export const LatestBlogs = () => {
    return useQuery(Queries.LatestBlogs);
}

export const Peoples = (category, limit) => {
    return useQuery(Queries.Peoples,{
        variables: {category: category, limit:limit}
    });
}

export const Interiors = () => {
    return useQuery(Queries.Interiors);
}

export const PeoplesTabs = () => {
    return useQuery(Queries.PeoplesTabs);
}

export const PeopleDetails = (url) => {
    return useQuery(Queries.PeopleDetails,{
        variables: {url: url}
    });
}
export const NewProjectPropertyTypes = () => {
    return useQuery(Queries.NewProjectPropertyTypes);
}
export const NewProjectBedrooms = () => {
    return useQuery(Queries.NewProjectBedrooms);
}
export const NewProjects = (filter_pstatus, region, sort, limit) => {
    return useQuery(Queries.NewProjects,{
        variables: {filter_pstatus: filter_pstatus, region, sort, limit}
    });
}

export const NewProjectsFilter = (filter_pstatus, filter_area, filter_category, filter_ptype, filter_priority, region, sort) => {
    return useQuery(Queries.NewProjectsFilter,{
        variables: {filter_pstatus: filter_pstatus, filter_area: filter_area, filter_category: filter_category, filter_ptype: filter_ptype, filter_priority, region, sort}

    });
}

export const NewProjectDetails = (url) => {
    return useQuery(Queries.NewProjectDetails,{
        variables: {url: url}
    });
}

export const PropertyDetails = (id) => {
    return useQuery(Queries.PropertyDetails,{
        variables: {id: id}
    });
}

export const CommunityList = () => {
    return useQuery(Queries.CommunityList);
}

export const CommunityDetails = (url) => {
    return useQuery(Queries.CommunityDetails,{
        variables: {url: url}
    });
}

export const PropertiesCount = (location) => {
    return useQuery(Queries.PropertiesCount,{
        variables: {location: location}
    });
}

export const SubCommunityDetails = (community) => {
    return useQuery(Queries.SubCommunityDetails,{
        variables: {community: community}
    });
}

export const CommunityDistrict = (url) => {
    return useQuery(Queries.CommunityDistrict,{
        variables: {url: url}
    });
}

export const Jobs = () => {
    return useQuery(Queries.Jobs);
}

export const JobsDetails = (url) => {
    return useQuery(Queries.JobsDetails,{
        variables: {url: url}
    });
}

export const AreaGuideDetails = (comm_district,community) => {
    return useQuery(Queries.AreaGuideDetails,{
        variables: {comm_district: comm_district, community:community}
    });
}

export const VideosGrid = () => {
    return useQuery(Queries.VideosGrid);
}

export const VideoDetail = (url) => {
    return useQuery(Queries.VideoDetail,{
        variables: {url: url}
    });
}

export const CommunitiesUrlFind = (name) => {
    return useQuery(Queries.CommunitiesUrlFind,{
        variables: {name: name}
    });
}

export const NegoDetails = (owner_name) => {
    return useQuery(Queries.NegoDetails,{
        variables: {owner_name: owner_name}
    });
}

export const CommunityCounterSale = (area_name) => {
    return useQuery(Queries.CommunityCounterSale,{
        variables: {area_name: area_name}
    });
}

export const NoResultProperties = (status, department) => {
    return useQuery(Queries.NoResultProperties, {
        variables: {status: status, department: department}
    });
}

export const PreQualificationQst = () => {
    return useQuery(Queries.PreQualificationQst);
}

export const PortfolioProperties = (nego_name) => {
    return useQuery(Queries.PortfolioProperties, {
        variables: {nego_name: nego_name}
    });
}

export const NewProjectsSaleDubai = () => {
    return useQuery(Queries.NewProjectsSaleDubai);
}

export const NewProjectsRentDubai = () => {
    return useQuery(Queries.NewProjectsRentDubai);

}
export const Deals = () => {
    return useQuery(Queries.Deals);

}
