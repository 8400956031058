import gql from "graphql-tag"

/* ========== All Insights ======== */
const Insights = gql`
query GetInsights($category: String! , $limit: Int!){
    insights(sort:"Date:desc",limit: $limit, where:{Category_contains:$category}){
        id
        Title
        URL
        Category
        Date
        Tag
        Tile_Image{
            url
            alternativeText
        }
        imagetransforms
        External_Link
    }
}`

/* ========== Insights Tabs ======== */
const InsightsTabs = gql`
query InsightsTabs{
    insights(sort:"published_at:desc"){
        Category
    }
}`

/* ========== Insights Details ======== */
const InsightsDetails = gql`
query InsightsDeatils($url: String!){
    insights(where:{URL:$url}){
        id
        Title
        Meta_Title
        Meta_Description
        URL
        Category
        Date
        Tag
        Banner_Image{
          url
          alternativeText
        }
        imagetransforms

        Author
        Highlight_Content
        Embed_Code
        Embed_Video_URL
        Content
        Show_Newsletter
        Office{
          id
          Name
          Direct_Phone
          Image{
            url
            alternativeText
          }
        }
        people{
            id
            Name
            URL
            Phone
            Tile_Image{
              url
              alternativeText
            }
          }
    }
}`


/* ========== Other Insights ======== */
const OtherInsights = gql`
query GetInsights($filter_tag: String!){
    insights(sort:"createdAt:desc", limit:8, where:{Tag_contains:$filter_tag}){
        id
        Title
        URL
        Category
        Date
        Tag
        Tile_Image{
            url
            alternativeText
        }
        imagetransforms
        External_Link
    }
}`

/* ========== Community detail page new priject data ======== */
const NewProjectSale = gql`
query NewProjects($city_name: String!, $type: String!) {
    newProjects(sort:"createdAt:desc", where:{City_contains:$city_name Region:"Qatar" Property_Status: $type}){
        id
        URL
        Name
        Project_Type
        Property_Status
        Category
        Listing_Status
        Price_Range
        Price_Starting_From
        Short_Description
        Priority
        Youtube_Video_URL
        Region
        Title_Image {
            url
            alternativeText
        }
        imagetransforms
    }
}`

/* ========== Latest Blogs ======== */
const LatestBlogs = gql`
query GetInsights{
    insights(sort:"createdAt:desc",  where:{Category:"Blogs"}, limit: 20){
        id
        Title
        URL
        Category
        Author
        Date
        Tag
        Tile_Image{
            url
            alternativeText
        }
        imagetransforms
        External_Link
    }
}`


/* ========== All Peoples ======== */
const Peoples = gql`
query GetAllPeoples($category: String!, $limit: Int!){
    peoples(sort:"Sort_Order:asc", where:{Category_contains:$category}, limit: $limit){
        id
        Name
        URL
        Designation
        Category
        Calendly_Link
        Embed_Video_URL
        Sort_Order
        Image{
            url
            alternativeText
        }
        Tile_Image{
            url
            alternativeText
        }
        imagetransforms
        Customer_Reviews{
            id
            Rating
        }
    }
}`

/* ========== All Interiors ======== */
const Interiors = gql`
query GetAllInteriors{
    interiorDesigns{
        id
        Title
        Tile_Image {
          url
          alternativeText
        }
        Slug
    }
}`

/* ========== Peoples Tab ======== */
const PeoplesTabs = gql`
query GetPeoplesTabs{
    peoples(sort:"published_at:desc"){
        Category
    }
}`

/* ========== People Details ======== */
const PeopleDetails = gql`
query PeopleDeatils($url: String!){
    peoples(where:{URL:$url}){
        id
        Name
        URL
        Meta_Title
        Meta_Description
        Highlight_Text
        About
        Rating
        Areas_of_Speciality
        Designation
        Category
        Phone
        Email
        Linked_In
        WhatsApp
        Calendly_Link
        Embed_Video_URL
        Image{
            url
            alternativeText
        }
        Tile_Image{
            url
            alternativeText
        }
        imagetransforms
        Customer_Reviews{
            id
            Name
            Embed_Video_URL
            Content
            Category
            Rating
            User_Photo{
                url
                alternativeText
            }
            imagetransforms
        }
        My_Property_Videos {
            Description
            Title
            Videos_List {
                Sort
                Tile_Image {
                    url
                }
                Video_Orignal {
                    url
                }
                Video_Title
                Video_Type
                Video_Url
            }
        }
        Team_Insights {
            Show_Insights
            Filter_by_Tag
          }
    }
}`
/* ==========   New Project Property Types  ======== */
export const NewProjectPropertyTypes = gql`
  query GetNewProjectPropertyTypes {
    newProjectPropertyTypes {
      id
      Type
    }
  }
`;
/* ==========   New Project Bedrooms  ======== */
export const NewProjectBedrooms = gql`
  query GetNewProjectBedrooms {
    newProjectBedrooms {
      id
      Bedroom
    }
  }
`;
/* ========== New Projects ======== */
const NewProjects = gql`

query NewProjects($filter_pstatus: String!, $region: String!, $sort: String!, $limit: Int!){
    newProjects(sort:$sort, limit: $limit, where:{Property_Status_contains:$filter_pstatus, Region:$region}){
        id
        URL
        Name
        Project_Type
        Property_Status
        Category
        Listing_Status
        Price_Range
        Price_Starting_From
        Short_Description
        Priority
        City
        Developer
        Completion_Date
        Youtube_Video_URL
        Region
        PropertyTypeAndBedroom {
            id
            Type {
              Type
              id
            }
            bedrooms {
              Bedroom
              id
            }
        }
        Title_Image {
            url
            alternativeText
        }
        imagetransforms
    }
}`

/* ========== New Projects Filter ======== */
const NewProjectsFilter = gql`
query NewProjectsFilter($filter_area: String!, $filter_category: String!, $filter_ptype: String!, $filter_pstatus: String!, $filter_priority: String!, $region: String!, $sort: String!){
    newProjects(sort:$sort, where:{Property_Status_contains:$filter_pstatus, Region:$region, Name_contains:$filter_area, Category_contains:$filter_category, Project_Type_contains:$filter_ptype, Priority_contains:$filter_priority}){
        id
        URL
        Name
        Project_Type
        Property_Status
        Category
        Listing_Status
        Price_Range
        Price_Starting_From
        Short_Description
        Priority
        Completion_Date
        Youtube_Video_URL
        Region
        Title_Image {
            url
            alternativeText
        }
        imagetransforms
    }
}`

/* ========== New Projects Sales In Dubai ======== */
const NewProjectsSaleDubai = gql`
query NewProjects {
    newProjects(sort:"Featured_Sort:asc", where:{Property_Status_contains: "Sale", Region: "Dubai"}) {
        id
        URL
        Name
        Featured_Sort
        Region
        Property_Status
    }
}`

/* ========== New Projects Rent In Dubai ======== */
const NewProjectsRentDubai = gql`
query NewProjects {
    newProjects(sort:"Featured_Sort:asc", where:{Property_Status_contains: "Rent", Region: "Dubai"}) {
        id
        URL
        Name
        Featured_Sort
        Region
        Property_Status
    }
}`

/* ========== New Project Details ======== */
const NewProjectDetails = gql`
query NewProjectDetails($url: String!) {
    newProjects(where:{URL:$url}) {
        id
        Name
        URL
        Short_Description
        Long_Description
        Property_Status
        Project_Type
        Category
        Listing_Status
        Price_Range
        Price_Starting_From
        Price_Per_Sqft
        Priority
        Developer
        Development_Type
        Ownership
        Land_Area_Square_Meters
        Gross_Floor_Area_Square_Meters
        Youtube_Video_URL
        Title_Image {
          url
          alternativeText
        }
        Upload_Images {
            url
            alternativeText
        }
        Floorplans {
            url
        }
        imagetransforms
        Key_Features
        Development_Strategy
        Our_Payment_Plan
        Payment_Plan_Module {
            Payment_Plan {
                Payment_Installments
                Schedule_of_payments
                Signature_Date 
                id
            }
        }
        Inventory_Trend
        Region
        id
        Meta_Title
        Meta_Description
        Address
        Latitude
        Longitude
        Select_Popular_Search
        Available_Units {
            available_properties {
                area
                title
                display_address
                address
                id
                price
                slug
                department
                search_type
                images
                imagetransforms
            }
        }
    }
}`

/* ========== Property Details ======== */
const PropertyDetails = gql`
query PropertyDetails($id: ID!) {
    property (id:$id) {
        id
        crm_id
        search_type
        department
        status
        images
        virtual_tour
        floorplan
        price
        display_address
        title
        long_description
        latitude
        longitude
        accommodation_summary
        facts
        score
        bedroom
        bathroom
        building
        address
        imagetransforms
        negotiator_details
        propertyID
        furnished
        size
        sublisting_type
        lots
        project_rate
        sold_or_rented_date
        publish
      }
}`

/* ========== Communities ======== */
const CommunityList = gql`
query CommunityList{
    communities{
        id
        Name
        URL
        Location
        Content
        Tile_Image{
          url
          alternativeText
        }
        imagetransforms
    }
}`

/* ========== Community Details ======== */
const CommunityDetails = gql`
query CommunityDetails($url: String!){
    communities(where:{URL:$url}){
        id
        Name
        URL
        Location
        Latitude
        Longitude
        rent_button_label
        rent_button_link
        sale_button_label
        sale_button_link
        Content
        Meta_Title
        Meta_Description
        Banner_Image{
            url
            alternativeText
        }
        imagetransforms
        Intro_Content{
            Intro_Title
            Intro_Content
            Intro_Text
            Choose_Office {
                Name
                Direct_Phone
                Image {
                    url
                    alternativeText
                }
            }
            Intro_Services_Title
            Intro_Services_List {
              Service_Text
              Service_Icon {
                url
              }
            }
            Intro_Accordian_Title
            Intro_Accordian_List {
              Content
              Title
            }
        }
        Show_Property{
            Title
            Show_Property
            Stb_List{
                Stb_List_Title
            }
        }
        Show_NewProject_Property{
            Title
            Publish
        }
        Show_Google_Reviews{
            Show_Google_Review
        }
        Show_Insights {
            Filter_by_Tag
            Show_Insights
          }
        Community_Districts{
            id
            Name
            URL
            Tile_Image{
                url
                alternativeText
            }
            imagetransforms
        }
        popular_search_communities {
            Module_Name
            ModuleType {
                ...on ComponentModulesContentBlock {
                    Title
                    Content
                    id
                }
            }
        }
    }
}`

/* ========== Community District Details ======== */
const CommunityDistrict = gql`
query CommunityDistrict($url: String!){
    communityDistricts(where:{URL:$url}){
        id
        Name
        URL
        Location
        Latitude
        Longitude
        Content
        Meta_Title
        Meta_Description
        community {
            Name
        }
        Banner_Image{
            url
            alternativeText
        }
        imagetransforms
        Intro_Content{
            Intro_Title
            Intro_Content
            Intro_Text
            Choose_Office {
                Name
                Direct_Phone
                Image {
                    url
                    alternativeText
                }
            }
            Intro_Services_Title
            Intro_Services_List {
              Service_Text
              Service_Icon {
                url
              }
            }
            Intro_Accordian_Title
            Intro_Accordian_List {
              Content
              Title
            }
        }
        Accordion_Content{
            Add_Accor_Item{
              Title
              Content
            }
        }
        Show_Property{
            Title
            Show_Property
        }
        Show_Google_Reviews{
            Show_Google_Review
        }
        Show_Counter_Block {
            Show_Counter_Block
            Counter_Block {
              CTA_Label
              CTA_Link
              Name
              id
              Count
            }
        }
        popular_search_communities {
            Module_Name
            ModuleType {
                ...on ComponentModulesContentBlock {
                    Title
                    Content
                    id
                }
            }
        }
    }
}`

/* ========== Sub Community District Details ======== */
const SubCommunityDetails = gql`
query CommunityDistrict($community: String!){
    communityDistricts(where:{community:{Name:$community}}){
        Name
        community {
            Name
        }
        Intro_Content {
            Intro_Text
        }
    }
}`

/* ========== Job Role ======== */
const Jobs = gql`
query Jobs {
    jobs {
        Title
        URL
        Meta_Title
        Meta_Description
        Salary_Range
        Your_Role
        id
        Annual_Income
    }
}`

/* ========== Job Role ======== */
const JobsDetails = gql`
query JobsDetails($url: String!) {
    jobs(where:{URL:$url}) {
        Title
        URL
        Meta_Title
        Meta_Description
        Salary_Range
        Your_Role
        Address
        Hours
        About_you
        Required_Attributes
        Content
        Annual_Income
    }
}`

/* ========== Areaguide ======== */
const AreaGuideDetails = gql`
query AreaGuideDetails($comm_district: String!, $community: String!) {
    communityDistricts(where:{Name_contains:$comm_district}) {
        id
        Name
        Content
        URL
        Tile_Image {
            url
            alternativeText
        }
        Embed_Video_Url
        imagetransforms
        community {
            URL
        }
    }
    communities(where:{Name_contains:$community}) {
        id
        Name
        Content
        URL
        Tile_Image {
            url
            alternativeText
        }
        Embed_Video_Url
        imagetransforms
    }
}`

/* ========== Videos Grid ======== */
const VideosGrid = gql`
query VideosGrid {
    videos {
        id
        Title
        URL
        Meta_Title
        Meta_Description
        Content
        Image {
          url
          alternativeText
        }
        Embed_Video_URL
        imagetransforms
    }
}`

/* ========== Video Detail ======== */
const VideoDetail = gql`
query VideoDetail ($url: String!) {
    videos (where:{URL:$url}) {
        id
        Title
        URL
        Meta_Title
        Meta_Description
        Content
        Image {
          url
          alternativeText
        }
        Embed_Video_URL
        imagetransforms
    }
}`

/* ========== Communities ======== */
const CommunitiesUrlFind = gql`
query CommunityList($name: String!) {
    communities(where:{Name_contains:$name}){
        id
        Name
        URL
    }
}`


/* ========== Nego Details ======== */
const NegoDetails = gql`
query NegoDetails($owner_name: String!){
    peoples(sort:"Sort_Order:asc", where:{Name_contains:$owner_name}){
        id
        Name
        URL
        Phone
        Email
        Whatsapp_No:WhatsApp
        Image:Tile_Image{
            url
            alternativeText
        }
        imagetransforms
    }
}`

/* ========== Community Counter Sales ======== */
const CommunityCounterSale = gql`
query CommunityCounterSale($area_name: String!){
    newProjectsSale:newProjects(where:{Name_contains:$area_name, Property_Status:"Sale"}) {
        id
        Name
        Property_Status
    }
    propertiesSale:properties(where:{display_address_contains:$area_name, status:"for sale", department:"residential", publish:true}) {
        id
        area
        search_type
        status
        slug
        title
        department
    }
    newProjectsRent:newProjects(where:{Name_contains:$area_name, Property_Status:"Rent"}) {
        id
        Name
        Property_Status
    }
    propertiesRent:properties(where:{display_address_contains:$area_name, status:"for rent", department:"residential", publish:true}) {
        id
        area
        search_type
        status
        slug
        title
        department
    }

}`

/* ========== No Results Properties ======== */
const NoResultProperties = gql`
query NoResultProperties($status: String!, $department: String!){
    properties(limit:6, where:{status:$status, department:$department, publish:true}) {
        id
        area
        display_address
        search_type
        status
        slug
        title
        department
        price
        images
    }

}`

/* ========== PreQualificationQst ======== */
const PreQualificationQst = gql`
query PreQualificationQst{
    preQualifiedQuestionsAnswer {
        Property_Type {
          Property_Type
        }
        Credit_Bureau_Score {
          Credit_Bureau_Score
        }
        Business_Type {
          Business_Type
        }
    }
}`

/* ========== No Results Properties ======== */
const PortfolioProperties = gql`
query PortfolioProperties($nego_name: String!){
    propertiesSale:properties(where:{status:"for sale", negotiator_owner:$nego_name, publish:true}) {
        id
        area
        display_address
        search_type
        status
        slug
        title
        department
        price
        images
        negotiator_owner
    }
    propertiesRent:properties(where:{status:"for rent", negotiator_owner:$nego_name, publish:true}) {
        id
        area
        display_address
        search_type
        status
        slug
        title
        department
        price
        images
        negotiator_owner
    }
    propertiesSold:properties(where:{status:"sold", negotiator_owner:$nego_name, publish:true}) {
        id
        area
        display_address
        search_type
        status
        slug
        title
        department
        price
        images
        negotiator_owner
    }
    propertiesLet:properties(where:{status:"rented", negotiator_owner:$nego_name, publish:true}) {
        id
        area
        display_address
        search_type
        status
        slug
        title
        department
        price
        images
        negotiator_owner
    }

}`

/* ========== No Results Properties ======== */
const PropertiesCount = gql`
query PropertiesCount($location: String!){
    properties(limit:6, where:{display_address_contains:$location, publish:true}) {
        id
        area
        display_address
        search_type
        status
        slug
        title
        department
        price
        images
    }

}`

/* ========== Deals ======== */
const Deals = gql`

query Deals{
    deals(sort:"createdAt:asc"){
        Type
        Appointment_Link
        Call_On
        Deposit_Amount
        Description
        Initial_Payment
        Interest_Rate
        Monthly_Payment
        Purchase_Price
        Deal_Name
        Representative
        Sort
        id
    }
}`

export const Queries = {
    Insights,
    InsightsTabs,
    InsightsDetails,
    OtherInsights,
    Peoples,
    Interiors,
    PeoplesTabs,
    PeopleDetails,
    NewProjectPropertyTypes,
    NewProjectBedrooms,
    NewProjects,
    NewProjectsFilter,
    NewProjectDetails,
    PropertyDetails,
    CommunityList,
    CommunityDetails,
    SubCommunityDetails,
    CommunityDistrict,
    NewProjectSale,
    Jobs,
    JobsDetails,
    AreaGuideDetails,
    VideosGrid,
    VideoDetail,
    CommunitiesUrlFind,
    LatestBlogs,
    NegoDetails,
    CommunityCounterSale,
    NoResultProperties,
    PreQualificationQst,
    PortfolioProperties,
    NewProjectsSaleDubai,
    NewProjectsRentDubai,
    PropertiesCount,
    Deals
};
